import { ChangeDetectionStrategy, ChangeDetectorRef, Component, input, Input, OnInit } from '@angular/core';
import { Select, Store } from '@ngxs/store';
import { Observable } from 'rxjs';
import { INavigationItem } from 'apps/early-stage-office/src/app/core/models/navigation.interface';
import { LayoutState } from 'apps/early-stage-office/src/app/core/store/layout/layout.state';
import { ActivatedRoute, ActivatedRouteSnapshot, NavigationEnd, Params, Router } from '@angular/router';
import { WithDestroyComponent } from 'apps/early-stage-office/src/app/core/abstract/abstract-with-destroy-component';
import { filter, takeUntil } from 'rxjs/operators';
import { ProfileState } from 'apps/early-stage-office/src/app/core/store/profile/profile.state';
import _ from 'lodash';
import { SupportNavigationItemComponent } from '../support-navigation-item/support-navigation-item.component';
import { NavigationItemComponent } from './navigation-item/navigation-item.component';
import { AsyncPipe, NgFor, NgIf } from '@angular/common';

@Component({
    selector: 'es-navigation',
    templateUrl: './navigation.component.html',
    styleUrls: ['./navigation.component.scss'],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, NgFor, NavigationItemComponent, SupportNavigationItemComponent, AsyncPipe],
})
export class NavigationComponent extends WithDestroyComponent implements OnInit {
    private data: Params = {};
    private ignoredItems: Partial<INavigationItem>[];
    showSupportButton = input(true);

    public navigationItems: INavigationItem[];
    @Input() public modifiers: Array<'white'> = [];

    @Select(LayoutState.isMobile) public isMobile$: Observable<boolean>;

    public get classModifiers(): string {
        return this.modifiers?.map(a => 'is-' + a).join(' ') || '';
    }

    constructor(
        public store: Store,
        private route: ActivatedRoute,
        private router: Router,
        private cd: ChangeDetectorRef
    ) {
        super();
    }

    public ngOnInit() {
        this.store
            .select(LayoutState.navigationData)
            .pipe(takeUntil(this.destroy$))
            .subscribe(data => {
                this.data = data;
                this.setNavigationItems();
            });

        this.store
            .select(LayoutState.ignoredNavigationItems)
            .pipe(takeUntil(this.destroy$))
            .subscribe(ignored => {
                this.ignoredItems = ignored;
                this.setNavigationItems();
            });

        this.router.events
            .pipe(
                takeUntil(this.destroy$),
                filter(event => event instanceof NavigationEnd)
            )
            .subscribe(() => {
                this.setNavigationItems();
            });
    }

    public setNavigationItems(): void {
        const navItems = this.resolveNavigationItems();

        if (JSON.stringify(this.navigationItems) !== JSON.stringify(navItems)) {
            this.navigationItems = navItems;
        }

        this.cd.detectChanges();
    }

    public resolveNavigationItems(): INavigationItem[] {
        const route = this.route.snapshot;
        let defaultItems: INavigationItem[] = this.getRouteNavigation();

        if (!defaultItems) {
            return [];
        }

        if (this.ignoredItems && Array.isArray(this.ignoredItems)) {
            const filterItems = (items: INavigationItem[]): INavigationItem[] => {
                return items
                    .filter(item => !this.ignoredItems.some(ignored => ignored.title === item.title))
                    .map(item => {
                        if (item.children && item.children.length > 0) {
                            item.children = filterItems(item.children);
                        }
                        return item;
                    });
            };

            defaultItems = filterItems(defaultItems);
        }

        defaultItems = defaultItems.filter(item => (!item.roles ? true : this.store.selectSnapshot(ProfileState.someRole(item.roles))));

        const params = this.getRouteParams();
        const defaults = route.data.navigationDefaults;
        const allParams = { ...defaults, ...params };

        Object.assign(allParams, this.data);

        return defaultItems.map(item => this.mapNavigationItem(item, allParams));
    }

    private mapNavigationItem(item: INavigationItem, allParams: Record<string, any>): INavigationItem {
        const navItem = _.cloneDeep(item);

        if (navItem.disabledFunc) {
            navItem.disabled = navItem.disabledFunc(allParams);
        }

        if (navItem.url) {
            for (const key in allParams) {
                navItem.url = navItem.url.replace(`:${key}`, allParams[key]);
            }
        }

        if (navItem.children) {
            navItem.children = navItem.children.map(child => this.mapNavigationItem(child, allParams));
        }

        return navItem;
    }

    private getRouteParams(): Params {
        let parent = this.route;
        let lastParent = this.route;

        while (parent) {
            lastParent = parent;
            parent = parent.parent;
        }

        return this.getChildRouteParams(lastParent.snapshot);
    }

    private getChildRouteParams(route: ActivatedRouteSnapshot): Params {
        let params = {};
        if (route.params) {
            params = Object.assign(params, route.params);
        }

        for (const child of route.children) {
            params = Object.assign(params, this.getChildRouteParams(child));
        }

        return params;
    }

    private getRouteNavigation(): INavigationItem[] {
        let parent = this.route;
        let lastParent = this.route;

        while (parent) {
            lastParent = parent;
            parent = parent.parent;
        }

        return this.getChildRouteNavigation(lastParent.snapshot);
    }

    private getChildRouteNavigation(route: ActivatedRouteSnapshot): INavigationItem[] {
        if (route.data.navigation) {
            return route.data.navigation;
        }

        for (const child of route.children) {
            const childNav = this.getChildRouteNavigation(child);

            if (childNav) {
                return childNav;
            }
        }

        return null;
    }
}
